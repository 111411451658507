exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-boutique-index-tsx": () => import("./../../../src/pages/boutique/index.tsx" /* webpackChunkName: "component---src-pages-boutique-index-tsx" */),
  "component---src-pages-boutique-success-tsx": () => import("./../../../src/pages/boutique/success.tsx" /* webpackChunkName: "component---src-pages-boutique-success-tsx" */),
  "component---src-pages-confidentialite-tsx": () => import("./../../../src/pages/confidentialite.tsx" /* webpackChunkName: "component---src-pages-confidentialite-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact/success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-portfolio-video-tsx": () => import("./../../../src/pages/portfolio/video.tsx" /* webpackChunkName: "component---src-pages-portfolio-video-tsx" */),
  "component---src-pages-prestations-tsx": () => import("./../../../src/pages/prestations.tsx" /* webpackChunkName: "component---src-pages-prestations-tsx" */),
  "component---src-pages-tarifs-tsx": () => import("./../../../src/pages/tarifs.tsx" /* webpackChunkName: "component---src-pages-tarifs-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-order-page-index-tsx": () => import("./../../../src/templates/OrderPage/index.tsx" /* webpackChunkName: "component---src-templates-order-page-index-tsx" */),
  "component---src-templates-portfolio-tsx": () => import("./../../../src/templates/Portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-tsx" */),
  "component---src-templates-prestation-tsx": () => import("./../../../src/templates/Prestation.tsx" /* webpackChunkName: "component---src-templates-prestation-tsx" */)
}

